export enum DistributionRuleFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum DistributionRuleType {
  LANGUAGE = 'languages',
  GENDER = 'gender',
  CITY = 'city',
  PRODUCT = 'products',
  OFFICE_CODE = 'officeCode',
  // 20210804 - R1 Descope
  // RACE = "race",
  // AGE_GROUP = "ageGroup"
}

export enum DistributionRulePriority {
  ONE = '1',
  TWO = '2',
  // THREE = "3",
  // FOUR = "4"
}

export enum OpenLeadOptionEnum {
  SPECIFIC_CAMPAIGN = 'specificCampaign',
  ALL_CAMPAIGN = 'allCampaign',

  // PACS Customization PACSSGPPFAP-172
  GROUPED_CAMPAIGNS_BY_ELIGIBILITY_RULE = 'groupedCampaignsByEligibilityRule',
}

export type DistributionRule = {
  _id: string;
  name: string;
  description: string;
  openLeadOption?: OpenLeadOptionEnum;
  method: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  rules: DistributionRuleItem[];
};

export type DistributionRuleItem = {
  type: string;
  operator: string;
  priority?: number;
};
